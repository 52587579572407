import { batch, useDispatch, useSelector } from 'react-redux';
import { getAfisha, getAfishaInfo } from 'app/actions/AfishaActions';
import { getIsFetching, getSeanceDates } from 'app/selectors/AfishaSelectors';
import {
	getSelectedDay,
	getCurrentCityId,
	getIsKinokassa,
	getCurrentCinemas,
	getWithNewDesign,
} from 'app/selectors/AppSelectors';
import getReleasesSoon from 'app/actions/ReleasesSoonActions';
import { useLocation } from 'react-router-dom';
import { changePageTitle, setSelectedDay } from 'app/actions/AppActions';
import { setCurrentFiltersPage } from 'app/modules/filters/redux/actions';
import { useCallback, useEffect } from 'react';
import { FiltersPage } from 'app/modules/filters/types';
import { formatMoment, getDateFromSearch } from 'app/selectors/Helpers';
import { THIRTY_MINUTES_INTERVAL } from 'app/constants/SettingsConstants';
import GroupedAfishaViewComponent from 'app/components/afisha/GroupedAfishaViewComponent';
import DefaultAfishaViewComponent from 'app/components/afisha/DefaultAfishaViewComponent';

const AfishaComponent = ({
	isImax,
	isVip,
	isNotMovie,
	isProCulture,
}: {
	isImax?: boolean;
	isVip?: boolean;
	isNotMovie?: boolean;
	isProCulture?: boolean;
}) => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const isFetching = useSelector(getIsFetching);
	const seanceDates = useSelector(getSeanceDates);
	const selectedDay = useSelector(getSelectedDay);
	const cityId = useSelector(getCurrentCityId);
	const isKinokassa = useSelector(getIsKinokassa);
	const cinemasCount = useSelector(getCurrentCinemas).length;
	const withNewDesign = useSelector(getWithNewDesign);

	const hasSeveralCinemas = cinemasCount > 1;

	useEffect(() => {
		dispatch(setCurrentFiltersPage(isProCulture ? FiltersPage.PUSHKIN_CARD : FiltersPage.AFISHA));
	}, [isProCulture])

	useEffect(() => {
		let searchDate: string | null = null;

		if (search) {
			searchDate = getDateFromSearch(search);

			if (searchDate) {
				dispatch(setSelectedDay(moment(searchDate)));
			}
		}

		const trueDay = searchDate ?? formatMoment(selectedDay);
		const menuItem = document.querySelector<HTMLDivElement>('.menu--date .menu__item--active');

		if (cityId && cinemasCount) {
			batch(() => {
				dispatch(getAfishaInfo(cityId));
				dispatch(getAfisha(trueDay, cityId, { isImax, isVip, isNotMovie, isKinokassa, hasSeveralCinemas }));
				dispatch(getReleasesSoon(cityId));
			})
		}

		const intervalId = setInterval(() => {
			const date = menuItem
				? formatMoment(moment(menuItem.dataset.date || {}))
				: trueDay;

			batch(() => {
				dispatch(getAfishaInfo(cityId));
				dispatch(getAfisha(date, cityId, { isImax, isVip, isNotMovie, isKinokassa, hasSeveralCinemas }));
			});

		}, THIRTY_MINUTES_INTERVAL);

		changePageTitle(i18n.t('Afisha'));

		return () => {
			clearInterval(intervalId);
		};
	}, [cityId, isImax, isVip, isNotMovie, hasSeveralCinemas, isKinokassa, cinemasCount]);

	const handleChangeDate = useCallback((date) => {
		dispatch(getAfisha(date, cityId, { isImax, isVip, isNotMovie, isKinokassa, hasSeveralCinemas}));
	}, [cityId, isImax, isVip, isNotMovie, isKinokassa, hasSeveralCinemas]);

	const page = isProCulture ? 'pushkin_card' :
		isNotMovie ? 'not_movies' :
		isVip ? 'afisha-vip' :
		isImax ? 'afisha-imax' : 'afisha';

	return withNewDesign ? (
			<GroupedAfishaViewComponent
				handleChangeDate={handleChangeDate}
				isFetching={isFetching}
				seanceDates={seanceDates}
				page={page}
				isImax={isImax}
				isVip={isVip}
			/>
		) : (
			<DefaultAfishaViewComponent
				handleChangeDate={handleChangeDate}
				isFetching={isFetching}
				isImax={isImax}
				isProCulture={isProCulture}
				isVip={isVip}
				isNotMovie={isNotMovie}
				page={page}
				seanceDates={seanceDates}
			/>
		)
}

export default AfishaComponent;