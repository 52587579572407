import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AfishaComponent from 'app/components/afisha/AfishaComponent';
import NewsContainer from 'app/containers/NewsContainer';

interface IProps { addedPath: string; }

const MonitorImaxPageComponent = ({ addedPath }: IProps) => (
	<Switch>
		<Redirect exact from={`${addedPath}/imax`} to={`${addedPath}/imax/afisha`} />
		<Route path={`${addedPath}/imax/afisha`}>
			<AfishaComponent isImax />
		</Route>
		<Route path={`${addedPath}/imax/news`}>
			<NewsContainer isImax />
		</Route>
	</Switch>
);

export default MonitorImaxPageComponent;
