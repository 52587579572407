import React from 'react';
import { useSelector } from "react-redux";
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import WorkTime from "app/components/WorkTime";
import {
	getAddresses,
	getCinema,
	getCinemaEmail,
	getCinemaOpeningHours,
	getContactsPhones,
	getCurrentCityTitle,
	getFooterPhones,
	getIsArman3D,
	prepareSeveralCinemasFooterLinks,
} from "app/selectors/AppSelectors";
import { parsePhone, setYaReachGoal } from "app/selectors/Helpers";
import { Phone } from "app/types/common";

interface IProps {
	cinemaID: number | null;
	isSeveralCinemasInCity: boolean;
	prefix: string;
}

function FooterContactsComponent({ cinemaID, isSeveralCinemasInCity, prefix }: IProps) {
	const phones = useSelector(getFooterPhones);
	const phonesWithInfo = useSelector(getContactsPhones);
	const cinemaOpeningHours = useSelector(getCinemaOpeningHours);
	const severalCinemasFooterLinks = useSelector(prepareSeveralCinemasFooterLinks);
	const addresses = useSelector(getAddresses);
	const isArman3D = useSelector(getIsArman3D);
	const currentCity = useSelector(getCurrentCityTitle);
	const cinemaEmail = useSelector(getCinemaEmail);
	const cinema = useSelector(getCinema);
	const {address, work_hours: workHours, city} = cinema;

	const renderSeveralCinemasLinks = () => (
		<div className="footer__several-cinemas-links-wrapper">
			{severalCinemasFooterLinks.map(({id, title, address}) => {
				return (
					<div key={id} className="footer__address-title">
						<KeydownEnterLink
							className="footer__subtitle footer__links-item"
							to={`${prefix}/cinemas/${id}/about`}
						>
							{title}
						</KeydownEnterLink>
						<span>{address}</span>
					</div>
				);
			})}
		</div>
	);

	const renderCinemaAddress = () => {
		const {maps} = addresses;

		return (
			<div className="footer__address-title">
				{!_.isEmpty(maps) ? (
					<KeydownEnterLink
						type="plain"
						className="footer__links-item"
						href={maps || ''}
						target="_blank"
						rel="noopener noreferrer"
					>
						{address}
					</KeydownEnterLink>
				) : (
					<span>{address}</span>
				)}
			</div>
		);
	};

	const renderPhoneNumbersWithInfo = (phones: Phone[]) => (
		<div className="footer__phone-numbers">
			<h3 className="footer__column-title">{i18n.t('Contacts')}</h3>
			{phones.map((phone, index) => phone.value ? (
				<div className="footer__contact-item" key={index}>
					<KeydownEnterLink
						type="plain"
						className="footer__links-item"
						href={`${!isArman3D ? 'tel:' : 'https://wa.me/'}${parsePhone(phone.value)}`}
						onClick={() => setYaReachGoal('phone-click')}
					>
						{phone.value}
					</KeydownEnterLink>
					{phone.comment && <span className="footer__contact-comment">{phone.comment}</span>}
				</div>
			) : null)}
		</div>
	);

	const renderPhoneNumbers = (phones: string[]) => (
		<div className="footer__phone-numbers">
			<h3 className="footer__column-title">{i18n.t('Contacts')}</h3>
			{phones.map((phone, index) => (
				<KeydownEnterLink
					type="plain"
					className="footer__links-item"
					href={`${!isArman3D ? 'tel:' : 'https://wa.me/'}${parsePhone(phone)}`}
					key={index}
					onClick={() => setYaReachGoal('phone-click')}
				>
					{phone}
				</KeydownEnterLink>
			))}
		</div>
	);

	return (
		<>
			<div className="footer__contacts-wrapper">
				<h2 className="footer__column-title footer__column-title--address">
					<span className="footer__city-title">{currentCity || city}</span>
				</h2>
				{isSeveralCinemasInCity ? (
					renderSeveralCinemasLinks()
				) : (
					<React.Fragment>
						{renderCinemaAddress()}
						{
							phonesWithInfo.length
								? renderPhoneNumbersWithInfo(phonesWithInfo)
								: phones && phones.length > 0 && renderPhoneNumbers(phones)
						}
						{
							cinemaEmail
								? (
									<div className='footer__contact-item'>
										<KeydownEnterLink
											type="plain"
											className="footer__links-item"
											href={`mailto:${cinemaEmail}`}
											onClick={() => setYaReachGoal('email-click')}
										>
											<span className="footer__email-value">{cinemaEmail}</span>
										</KeydownEnterLink>
										<span className="footer__contact-comment">{i18n.t("Email")}</span>
									</div>
								) : null
						}
						{
							cinemaOpeningHours || !_.isEmpty(cinema.work_hours) && _.values(workHours).every(item => item)
								? (
									<div className="footer__work-time-wrapper">
										<h2 className="footer__column-title footer__column-title--worktime">{i18n.t('OpenHours')}</h2>
										<div className="footer__worktime">
											<WorkTime time={workHours} cinemaID={cinemaID} openingHours={cinemaOpeningHours} />
										</div>
									</div>
								)
								: null
						}
						<span className="footer__address-mall" />
					</React.Fragment>
				)}
				<span className="footer__address-mall" />
			</div>
			<div style={{display: 'flex'}}>
				<span className="footer__custom-1" />
				<span className="footer__custom-2" />
			</div>
		</>
	);
}

export default FooterContactsComponent;
