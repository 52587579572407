import { ReactNode, SyntheticEvent, useState } from "react";
import { MdKeyboardArrowDown } from "@react-icons/all-files/md/MdKeyboardArrowDown";
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import { ISubMenu } from "app/types/common";
import { useIsMobile } from "app/hooks";

interface IProps {
	title: NonNullable<ReactNode>,
	isCustomCinemaLink?: boolean,
	sub: ISubMenu[],
	handleClickItemMenu: (event: SyntheticEvent) => void,
}

function GroupLink({title, isCustomCinemaLink, sub, handleClickItemMenu}: IProps) {
	const isMobile = useIsMobile();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(!open);
	};

	return (
		<div className="footer__links--group">
			<div className="footer__links-title" onClick={handleOpen}>
				{title}
				<MdKeyboardArrowDown size={24} />
			</div>
			{open && isMobile && sub.map(subLink => (
				<KeydownEnterLink
					to={subLink.href}
					className="footer__links-item text--ellipsis"
					data-is-custom-cinema-link={isCustomCinemaLink}
					data-link-title={subLink.title}
					onClick={handleClickItemMenu}
				>
					{subLink.title}
				</KeydownEnterLink>
			))}
			{!isMobile && (
				<div className="footer__menu-sub">
					{sub.map((subLink, subIndex) =>
						<KeydownEnterLink
							type="navLink"
							key={subIndex}
							to={subLink.href}
							exact
							activeClassName="menu-sub__item--active"
							className="menu-sub__item text--ellipsis"
							data-is-custom-cinema-link={isCustomCinemaLink}
							data-link-title={subLink.title}
							onClick={handleClickItemMenu}
						>
							{subLink.title}
						</KeydownEnterLink>
					)}
				</div>)
			}
		</div>
	);
}

export default GroupLink;
