import { RESET_BANNERS, SET_BANNERS, SET_IS_FIRST_BANNER_LOADED } from 'app/modules/banners/redux/bannersConstants';
import { BannerType } from 'app/modules/banners/bannersTypes';

export const setBanners = (payload: BannerType[]) => ({
	type: SET_BANNERS,
	payload
});

export const setIsFirstBannerLoaded = (payload: boolean) => ({
	type: SET_IS_FIRST_BANNER_LOADED,
	payload
});

export const resetBanners = () => ({type: RESET_BANNERS});