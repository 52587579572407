import React, { useRef } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';

const linkTypes = {
	plain: 'a',
	navLink: NavLink,
	routerLink: Link,
};

interface IProps{
	className?: string;
	href?: string;
	to?: string;
	target?: string;
	rel?: string;
	style?: object;
	children?: React.ReactNode;
	type?: 'plain' | 'routerLink' | 'navLink';
	onClick?: (event: React.SyntheticEvent) => void;
	tabIndex?: number;
	activeClassName?: string;
	exact?: boolean;
}

const KeydownEnterLinkComponent = ({
	children,
	type = 'routerLink',
	onClick = _.noop,
	to = '',
	...restLinkProps
}: IProps) => {
	const history = useHistory();

	const ref = useRef<HTMLAnchorElement>(null);

	const isPlainLink = type === 'plain';

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.keyCode === 13) {
			onClick(event);

			return isPlainLink
				? ref?.current?.click()
				: history.push((event.target as HTMLAnchorElement).pathname);
		}
	};

	return React.createElement(
		linkTypes[type],
		{
			onKeyDown: handleKeyDown,
			onClick,
			...{
				...restLinkProps,
				ref: isPlainLink ? ref : undefined,
				to
			},
		},
		children,
	);
};

export default KeydownEnterLinkComponent;
