import React, {lazy, memo, useEffect, useLayoutEffect, useRef, useState} from 'react';
import parse from 'html-react-parser';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import classNames from 'classnames';

import Menu from 'app/containers/MenuContainer';
import Header from 'app/containers/HeaderContainer';
import FondKinoHeader from 'app/containers/FondKinoHeaderContainer';
import FooterContainer from 'app/containers/FooterContainer';
import PopupContainer from 'app/containers/PopupContainer';
import {MOBILE_MAX_SIZE, __DEV__, TABLET_MAX_SIZE} from 'app/constants/AppConstants';
import {isFondKino} from 'app/selectors/AppSelectors';
import {loadComponent, setYaReachGoal} from 'app/selectors/Helpers';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import {VREMYAKINO_CINEMA_ID} from 'app/constants/CinemaConstants';
import {AppRoutes} from 'app/components/AppRoutes';
import isNumeric from 'validator/es/lib/isNumeric';
import {getAnalyticsRequest, appendYaBase} from 'app/actions/AnalyticsActions';
import Refund from 'app/containers/ResendEmailPopupContainer';
import { useMobileViewBreakPoint, usePrevious } from 'app/hooks';
import { Alert } from 'app/types/common';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
	getAppData, setIsMobile, showPopup, hidePopup, getFondKinoAppData, setCountUnreadNotifications,
	setCurrentCityId, setIsUserAuth, setIsWidgetInKioskMode, handleChangingCity
} from 'app/actions/AppActions';
import {
	getCinema, getMenuLinks, getIsSemiblind, getCurrentCityId, getIsSmartBannerShown, getAlert,
	getCurrentCinemaId, getBranding, getIsMonitor, getIsKinokassa, getCurrentCinemas, getWithNewDesign,
} from 'app/selectors/AppSelectors';
import { IReduxState } from 'app/types/state';
import { getHasBanners } from 'app/modules/banners/redux/bannersSelectors';
import { useSiteReloading } from 'app/hooks/useSiteReloading';

const BannersSliderComponent = loadComponent(lazy(() => import('app/modules/banners/components/BannersSliderComponent')));
const ViewContainer = loadComponent(lazy(() => import('app/containers/ViewContainer')));
const SmartBannerContainer = loadComponent(lazy(() => import('app/containers/SmartBannerContainer')));

const location = window.location;
const isPreview = location.search === '?preview';
const isRefund = location.pathname.includes('/refund');

export default memo(function AppComponent() {
	const dispatch = useDispatch();

	const {
		alert, cinema, currentCinemaId, cityId, isSmartBannerShown,
		menuLinks, isSemiblind, currentCinemas, isKinokassa,
		isMonitor, branding, withNewDesign, hasBanners,
	} = useSelector((state: IReduxState) => ({
		alert: getAlert(state),
		cinema: getCinema(state),
		currentCinemaId: getCurrentCinemaId(state),
		isSmartBannerShown: getIsSmartBannerShown(state),
		menuLinks: getMenuLinks(state),
		isSemiblind: getIsSemiblind(state),
		currentCinemas: getCurrentCinemas(state),
		isKinokassa: getIsKinokassa(state),
		cityId: getCurrentCityId(state),
		branding: getBranding(state),
		isMonitor: getIsMonitor(state),
		withNewDesign: getWithNewDesign(state),
		hasBanners: getHasBanners(state),
	}), shallowEqual)

	const prevBranding = usePrevious(branding);
	const prevAlert = usePrevious(alert);
	const prevCurrentCinemas = usePrevious(currentCinemas);
	const prevCityId = usePrevious({cityId});

	const backgroundRef = useRef<HTMLAnchorElement | null>(null);

	const [isAppDataLoaded, setIsAppDataLoaded] = useState(false);

	const isTablet = useMobileViewBreakPoint(TABLET_MAX_SIZE);
	const {isMobileView} = useMobileViewBreakPoint(MOBILE_MAX_SIZE);

	const brandingEnabled = !withNewDesign && !_.isEmpty(branding) && branding.enabled;

	useSiteReloading();

	useEffect(() => {
		const pathCityId = location.pathname.split('/')[1];
		const isUserAuth = localStorage.getItem('isUserAuth');
		const isWidgetInKioskMode = localStorage.getItem('isWidgetInKioskMode');

		if (typeof isUserAuth === 'string') {
			dispatch(setIsUserAuth(JSON.parse(isUserAuth)));
		}

		if (typeof isWidgetInKioskMode === 'string') {
			dispatch(setIsWidgetInKioskMode(JSON.parse(isWidgetInKioskMode)));
		}

		if (pathCityId && isNumeric(pathCityId)) {
			dispatch(setCurrentCityId(+pathCityId));
		}

		if (!__DEV__ && !isKinokassa) {
			appendYaBase();
		}
	}, []);

	useEffect(() => {
		window.addEventListener('message', setAuthStatus);
		window.addEventListener('message', setCounterNotifications);
		window.addEventListener('message', setWidgetKioskModeStatus);
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', setAuthStatus);
			window.removeEventListener('message', setWidgetKioskModeStatus);
			window.removeEventListener('message', setCounterNotifications);
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	useEffect(() => {
		dispatch(setIsMobile(isMobileView));
	}, [isMobileView]);

	useLayoutEffect(() => {
		if (prevBranding !== branding && !_.isEmpty(branding) && branding.enabled) {
			if (branding?.bg_color) document.body.style.backgroundColor = branding.bg_color;

			if (backgroundRef.current) {
				backgroundRef.current['style']["backgroundImage"] = `url(${branding.img_url})`
			}
		}
	}, [branding]);

	useEffect(() => {
		if (alert && prevAlert !== alert && !cinema.isKinokassa) {
			const popupContentNode = getPopupContentNode(alert);

			dispatch(showPopup({
				popupContentNode,
				withCloseButton: false
			}));
		}
	}, [alert, cinema]);

	useEffect(() => {
		if (!__DEV__ && !isKinokassa &&
			((!prevCurrentCinemas?.length && currentCinemas.length) || (prevCityId?.cityId && cityId !== prevCityId?.cityId))
		) {
			const currentCinemasIds = currentCinemas.map((cinemaObj) => cinemaObj.id);

			getAnalyticsRequest(currentCinemasIds);
		}
	}, [isKinokassa, currentCinemas, cityId]);

	useEffect(() => {
		(dispatch(currentCinemaId ? getAppData(currentCinemaId) : getFondKinoAppData()) as any as Promise<any>)
			.then(() => setIsAppDataLoaded(true))
	}, []);

	useEffect(() => {
		if (typeof cityId === "number" && isAppDataLoaded) {
			dispatch(handleChangingCity);
		}
	}, [cityId, isAppDataLoaded]);

	function setAuthStatus({data: {isUserLoggedIn}}) {
		if (isUserLoggedIn !== undefined && isUserLoggedIn !== null) {
			localStorage.setItem('isUserAuth', isUserLoggedIn);
			dispatch(setIsUserAuth(JSON.parse(isUserLoggedIn)));
		}
	}

	function setWidgetKioskModeStatus({data: {isKioskMode}}) {
		if (isKioskMode !== undefined) {
			localStorage.setItem('isWidgetInKioskMode', isKioskMode);
			dispatch(setIsWidgetInKioskMode(isKioskMode));
		}
	}

	function setCounterNotifications({data: {countUnreadNotifications}}: {data: {countUnreadNotifications: string}}) {
		if (countUnreadNotifications) {
			localStorage.setItem('countUnreadNotifications', countUnreadNotifications);
			dispatch(setCountUnreadNotifications(JSON.parse(countUnreadNotifications)));
		}
	}

	function getPopupContentNode({title, subtitle}: Alert) {
		return (
			<div className="popup__content">
				<div className="popup__content__header">
					{title}
				</div>
				<div className="popup__notification">
					{parse(subtitle)}
				</div>
				<div className="popup__content__bottom" style={{justifyContent: 'center'}}>
					<KeydownEnterButton
						tabIndex={1}
						className="button button--accent"
						onClick={() => dispatch(hidePopup())}
					>
						{i18n.t('Close')}
					</KeydownEnterButton>
				</div>
			</div>
		);
	};

	function handleMessage({data: {reachGoal, params}}) {
		if (reachGoal) {
			setYaReachGoal(reachGoal, params || {});
		}
	};

	return (
		<BrowserRouter>
			<div
				className={classNames('app', {
					'app--with-branding': brandingEnabled && !isSemiblind
				})}
			>
				{brandingEnabled && !isRefund &&
					<a
						className={classNames('branding g-mobile-hide', {
							'branding--hide': isSemiblind
						})}
						ref={backgroundRef}
						href={branding.link || '#'}
						target={branding.new_tab ? '_blank' : '_self'}
						rel="noreferrer"
					/>
				}
				{isSemiblind && <ViewContainer />}
				{isRefund
					? (
						<Switch>
							<Route component={Refund} />
						</Switch>
					) : (
						<div className="wrapper">
							{!isPreview && isSmartBannerShown && (!isFondKino || isMonitor) && <SmartBannerContainer />}
							{withNewDesign
								? !isTablet.isMobileView ? <Header.Kinokassa /> : <Header.Mobile />
								: isFondKino || cinema.id === VREMYAKINO_CINEMA_ID ? <FondKinoHeader /> : <Header.Main />
							}
							{!withNewDesign && hasBanners && !isSemiblind ? <BannersSliderComponent /> : null}
							{!withNewDesign ? <Menu.Navigation links={menuLinks} /> : null}
							<AppRoutes />
							{withNewDesign ? <FooterContainer.Kinokassa /> : <FooterContainer.Main />}
						</div>
					)
				}
				<PopupContainer />
			</div>
		</BrowserRouter>
	);
});
