import React, {useRef, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import { IMenu } from 'app/types/common';
import classNames from 'classnames';
import MenuItem from 'app/components/header/MenuItem';
import FeedbackContainer from 'app/containers/FeedbackContainer';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import { closeIcon } from 'app/constants/IconsConstants';
import ContactsContainer from 'app/containers/header/ContactsContainer';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import { getWithNewDesign } from 'app/selectors/AppSelectors';
import RefundButton from 'app/components/refund/RefundButton';

interface IProps {
	links: IMenu[],
	feedback: boolean,
}

function MenuMobile({
	links,
	feedback,
}: IProps & RouteComponentProps) {
	const withNewDesign = useSelector(getWithNewDesign);
	const menuList = useRef<HTMLDivElement>(null);
	const [isOpenMenu, setIsOpenMenu] = useState(false);

	const toggleMenu = () => setIsOpenMenu(prev => !prev);
	const handleCloseMenu = () => setIsOpenMenu(false);

	const handlers = useSwipeable({
		onSwipedDown: () => handleCloseMenu(),
	});

	const renderAdditionalMenu = (links: IMenu[]) => {
		return (
			<div className={classNames('main-nav__sub main-nav__sub--mobile popover', {'popover--open': isOpenMenu})}>
				<div className="main-nav__links">
					<div className="main-nav__sub-header text text--size-18" {...handlers}>
						{i18n.t('Menu')}
						<KeydownEnterButton
							className="button--unset"
							onClick={toggleMenu}
						>
							{closeIcon}
						</KeydownEnterButton>
					</div>
					{links.map((link) => {
						return link.sub && link.sub.length
							? (
								<div className="main-nav__group-item" key={link.href}>
									<span className="text text--size-12 main-nav__group-title">
										{link.title}
									</span>
									{link.sub.map((sub, index) => (
										<MenuItem
											key={index}
											link={sub}
											onClick={handleCloseMenu}
											className="main-nav__sub-item main-nav__sub-item--group"
										/>
									))}
								</div>
							)
							: <MenuItem link={link} key={link.href} className="main-nav__sub-item" onClick={handleCloseMenu} />
					})}
					<div className="main-nav__group-item">
						<span className="text text--size-12 main-nav__group-title">
							{i18n.t('Help')}
						</span>
						<RefundButton linkClassName="text text--unlink main-nav__sub-item main-nav__sub-item--group"/>
					</div>
					{feedback && !withNewDesign && <FeedbackContainer />}
				</div>
				<div className="main-nav__footer">
					<ContactsContainer hasTitle />
				</div>
			</div>
		);
	};

	return (
		<nav className="main-nav menu--navigation">
			{isOpenMenu && <div className="popup-overlay" onClick={handleCloseMenu} />}
			<div className="menu-container" ref={menuList}>
				<button className={classNames('burger-button', {'burger-button--open': isOpenMenu})} onClick={toggleMenu}>
					<span></span>
				</button>
				<Portal>
					{renderAdditionalMenu(links)}
				</Portal>
			</div>
		</nav>
	);
}

export default withRouter(MenuMobile);
