import ReleaseItemComponent from 'app/components/ReleaseItemComponent';
import { MdKeyboardArrowLeft } from '@react-icons/all-files/md/MdKeyboardArrowLeft';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';
import React, { useEffect, useRef, MouseEvent } from 'react';
import { IRelease } from 'app/types/releases';
import * as style from 'css/grouped-releases.module.scss';
import { isMobileSelector } from 'app/selectors/AppSelectors';
import { useSelector } from 'react-redux';

const GroupOfReleases = ({
	groupKey,
	releasesOfGroup,
	page,
	isImax,
	isVip,
} : {
	page: string;
	groupKey: string;
	releasesOfGroup: IRelease[];
	isImax?: boolean;
	isVip?: boolean;
}) => {
	const isMobile = useSelector(isMobileSelector);
	const daysList = useRef<HTMLDivElement>(null);
	const leftScrollRef = useRef<HTMLDivElement>(null);
	const rightScrollRef = useRef<HTMLDivElement>(null);

	const handleListScroll = () => {
		if (!daysList.current || !leftScrollRef.current || !rightScrollRef.current) return;

		const {scrollLeft, scrollWidth, clientWidth} = daysList.current;

		if (scrollWidth === clientWidth) {
			leftScrollRef.current?.classList.add("g-hide");
			rightScrollRef.current?.classList.add("g-hide");

			return;
		}

		switch (true) {
			case scrollLeft < 10:
				leftScrollRef.current?.classList.add("g-hide");
				break;
			case scrollLeft + clientWidth > scrollWidth - 10:
				rightScrollRef.current?.classList.add("g-hide");
				break;
			default:
				leftScrollRef.current?.classList.remove("g-hide");
				rightScrollRef.current?.classList.remove("g-hide");
				break;
		}
	};

	useEffect(() => {
		handleListScroll();
	}, []);

	const handleScrollButtonClick = (event: MouseEvent<HTMLDivElement>) => {
		const direction = Number(event.currentTarget.dataset.direction)

		if (daysList.current) {
			daysList.current.scrollLeft += daysList.current.clientWidth * direction;
		}
	}

	return (
		<>
			<div className={style.groupTitle}>{i18n.t(`ReleaseGroups.${groupKey}`)}</div>
			<div className={style.groupContent}>
				{!isMobile &&
					<div ref={leftScrollRef} className={style.releasesScrollButton}>
						<div
							className={style.releasesScrollButtonIconWrapper}
							onClick={handleScrollButtonClick}
							data-direction="-1"
						>
							<MdKeyboardArrowLeft/>
						</div>
					</div>
				}
				<div
					ref={daysList}
					className={`${style.releasesContainerGrouped} releases-container releases`}
					onScroll={handleListScroll}
				>
					{
						releasesOfGroup.map((release, index) =>
							<ReleaseItemComponent release={release} key={index} page={page} isImax={isImax} isVip={isVip} />,
						)
					}
				</div>
				{!isMobile &&
					<div ref={rightScrollRef} className={[style.releasesScrollButton, style.releasesScrollButtonRight].join(" ")}>
						<div
							className={style.releasesScrollButtonIconWrapper}
							onClick={handleScrollButtonClick}
							data-direction="1"
						>
							<MdKeyboardArrowRight/>
						</div>
					</div>
				}
			</div>
		</>
	)
}

export default GroupOfReleases;
