import React, { useCallback, useEffect } from 'react';
import Menu from 'app/containers/MenuContainer';
import SeanceItem from 'app/containers/SeanceItemContainer';
import {getBusinessDate, getIsKinokassa, getWithNewDesign} from 'app/selectors/AppSelectors';
import {formatMoment} from 'app/selectors/Helpers';
import { ID_ELEMENT_RELEASE_SCHEDULE } from 'app/components/release_page/constants';
import SpinnerComponent from 'app/components/SpinnerComponent';
import {getSeances, resetSeances} from 'app/actions/ReleaseItemActions';
import {updateSelectedDay} from 'app/actions/AppActions';
import {getFilteredSeancesOfReleaseItem, getIsFetchingReleaseItemSeances} from 'app/selectors/ReleaseItemSelectors';
import {getSelectedDay, getCurrentCityId, getFilteredCinemasOfCurrentSeances} from 'app/selectors/AppSelectors';
import {getCinemas} from 'app/selectors/ReleaseSelectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'app/types/state';
import { usePrevious } from 'app/hooks';

interface IProps {
	releaseId: number;
	ramblerReleaseId: number;
	seanceDates: string[];
	modifier?: string;
}

const ReleaseScheduleComponent = ({modifier, ramblerReleaseId, releaseId, seanceDates} : IProps) => {
	const dispatch = useDispatch();

	const withNewDesign = useSelector(getWithNewDesign);
	const seances = useSelector((state: IReduxState) => getFilteredSeancesOfReleaseItem(state, modifier), shallowEqual);
	const selectedDay = useSelector(getSelectedDay);
	const cityId = useSelector(getCurrentCityId);
	const currentCinemas = useSelector(getFilteredCinemasOfCurrentSeances);
	const allCinemasCount = useSelector(getCinemas).length;
	const isFetchingSeances = useSelector(getIsFetchingReleaseItemSeances);
	const isKinokassa = useSelector(getIsKinokassa);

	const prevReleaseID = usePrevious(releaseId);

	useEffect(() => {
		dispatch(getSeances(releaseId, formatMoment(selectedDay), cityId));

		return () => dispatch(resetSeances());
	}, []);

	useEffect(() => {
		if (releaseId !== prevReleaseID) {
			dispatch(resetSeances());
		}
	}, [releaseId]);

	const handleChangeDate = useCallback((date) => {
		dispatch(getSeances(releaseId, date, cityId));
	}, [releaseId, cityId]);

	const firstSessionDate = _.first(seanceDates);
	const businessDate = getBusinessDate(moment(firstSessionDate));

	const renderSchedule = () => (
		<div>
			{_.map(currentCinemas, cinema =>
				(
					<div key={cinema.id}>
						<div className="release-schedule__item">
							{allCinemasCount > 1 ?
								<div className="release-schedule__cinema">
									<span className="text--large release-schedule__cinema-title">{cinema.title}</span>
									<span className="text--small release-schedule__cinema-address">{cinema.address}</span>
								</div>
								: null}
							<div className="release-schedule__items">
								{_.map(_.filter(seances, seanceItem => seanceItem.cinema_id === cinema.id), (seance) =>
									(!withNewDesign || isKinokassa
										? (
											<SeanceItem.Main
												seance={seance}
												releaseId={releaseId}
												ramblerReleaseId={ramblerReleaseId}
												key={seance.id}
												page="release"
											/>
										) : (
											<SeanceItem.withNewDesign
												seance={seance}
												releaseId={releaseId}
												ramblerReleaseId={ramblerReleaseId}
												key={seance.id}
												page="release"
											/>
										)
									))}
								</div>
						</div>
					</div>
				))}
		</div>
	)

	return (
		<div className="release-schedule-wrap" style={{flexGrow: 1}}>
			{
				!withNewDesign && (
					<Menu.Calendar
						seanceDates={seanceDates}
						onClick={handleChangeDate}
						isReleasePage
					/>
				)
			}
			<div className="release-schedule" id={ID_ELEMENT_RELEASE_SCHEDULE}>
				{
					isFetchingSeances
						? <SpinnerComponent/>
						: (
							<div className="release-schedule__list">
								{seances.length
									? renderSchedule()
									: <div className="release-schedule__item">
										<button
											className="seance-item time-tooltip"
											onClick={() => {
												updateSelectedDay(moment(firstSessionDate));
												handleChangeDate(firstSessionDate);
											}}
										>
											{`${i18n.t('Seances')} ${i18n.t('on')} ${businessDate.calendar()}`}
										</button>
									</div>}
							</div>
						)
				}
			</div>
		</div>
	);
}

export default ReleaseScheduleComponent;
