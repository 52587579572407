import {TOKEN} from 'app/constants/SettingsConstants';
import {FALLBACK_LANG} from 'app/constants/AppConstants';

export enum METHODS {
	GET = "GET",
	POST = "POST",
	PUT = "PUT",
	PATCH = "PATCH",
	DELETE = "DELETE",
}

const httpRequestPromise = <T = any>(
	path: string | URL,
	method = METHODS.GET,
	data?: any
) => new Promise<T>(function(resolve, reject) {
	const xhr = new XMLHttpRequest();
	xhr.open(method, path, true);

	xhr.setRequestHeader('X-Application-Token', TOKEN);
	xhr.setRequestHeader('X-Platform', 'widget');
	xhr.setRequestHeader('Content-Type', 'application/JSON');
	xhr.setRequestHeader('X-Preferred-Language', localStorage.getItem('lang') || FALLBACK_LANG);

	xhr.onload = function() {
		let response = this.response || '';

		if (response) {
			try {
				response = JSON.parse(response);
			} catch (error) {
				reject(new Error(error));
			}
		}

		if (this.status === 200 || this.status === 204 || this.status === 202) {
			resolve(response);
		} else {
			reject(response);
		}
	};

	xhr.onerror = function() {
		reject(new Error('Network Error'));
	};

	xhr.send(JSON.stringify(data));
});

export default httpRequestPromise;
