import { Cinema, IMenu, Phone, Socials } from "app/types/common";
import cn from 'classnames';
import { setYaReachGoal } from "app/selectors/Helpers";
import Address from "app/components/contacts/Address";
import Phones from "app/components/contacts/Phones";
import SocialLinks from "app/components/contacts/SocialLinks";
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import Menu from 'app/containers/MenuContainer';
import CitySelect from 'app/containers/CitySelect/CitySelectContainer';
import { searchIcon } from "app/constants/IconsConstants";
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import SearchRelease from "app/components/header/SearchRelease";
import {CSSProperties, useEffect, useState} from "react";
import ReleaseItem from "app/components/release_item/ReleaseItem";
import BannersSliderComponent from "app/modules/banners/components/BannersSliderComponent";
import RefundButton from "app/components/refund/RefundButton";
import { useSelector } from "react-redux";
import LoginButton from 'app/containers/LoginButtonContainer';
import { getTransformedBanners } from "app/modules/banners/redux/bannersSelectors";

interface IProps {
	cinema: Cinema,
	isMobile: boolean,
	addresses: {address: string | null, place: string | null, maps: string | null},
	phones: Phone[],
	socials: Partial<Socials>,
	isSeveralCinemasInCity: boolean,
	isCitySelectShown: boolean,
	menuLinks: IMenu[],
	hasBanners: boolean,
	isSemiblind: boolean,
	showPopup: (content: {popupContentNode: JSX.Element, className: string}) => void,
	isKinokassa: boolean
}

function Header({
	cinema,
	isMobile,
	addresses,
	phones,
	socials,
	isSeveralCinemasInCity,
	isCitySelectShown,
	menuLinks,
	hasBanners,
	isSemiblind,
	showPopup,
	isKinokassa,
}: IProps) {
	const banners = useSelector(getTransformedBanners);
	const isReleasePage = window.location.pathname.includes('release');
	const hasNeedShowBanners = !isReleasePage && hasBanners && !isSemiblind;
	const [poster, setPoster] = useState('');

	useEffect(() => {
		if (hasNeedShowBanners && banners.length && !isReleasePage) {
			setPoster(banners[0].original);
		}
	}, [banners, hasNeedShowBanners, isReleasePage]);

	const handleIconClick = (event) => {
		const {icon} = event.currentTarget.dataset;

		setYaReachGoal(`${icon}-click`);
	}

	const handleSearchRelease = () => {
		showPopup({
			popupContentNode: <SearchRelease />,
			className: 'search-release-container'
		});
	}

	return (
		<header
			id="header"
			className={cn('main-header', {'main-header--full': isReleasePage || hasNeedShowBanners})}
			style={{'--poster-url': `url(${poster})`} as CSSProperties}
		>
			<div className="main-header__top kassa-container">
				<div className="main-header__contacts">
					<Phones
						oldPhones={cinema.phoneNumbers}
						newPhones={phones}
						onClick={handleIconClick}
						isMobileMenu
					/>
					<Address
						cinema={cinema}
						addresses={addresses}
						isSeveralCinemasInCity={isSeveralCinemasInCity}
						onClick={handleIconClick}
					/>
					{!_.isEmpty(socials) && !isSeveralCinemasInCity
						? <SocialLinks
							className="social-links"
							linksData={socials}
							showTitles={false}
						/> : null}
				</div>
				<RefundButton linkClassName="text text--size-14 text--inter refund-btn"/>
			</div>
			<div className="main-header__bottom kassa-container">
				<div className="logo">
					<KeydownEnterLink
						type="plain"
						href="/"
						className="header__logo"
						rel="noopener noreferrer"
					>
						<img className="header__logo-img" src="/img/logo.svg" alt={i18n.t('Logo')} />
					</KeydownEnterLink>
				</div>
				{isCitySelectShown && <CitySelect.Kinokassa isDesktopView={!isMobile} />}
				<Menu.KinokassaNav links={menuLinks} />
				<KeydownEnterButton className="search-release" onClick={handleSearchRelease}>
					{searchIcon}
				</KeydownEnterButton>
				{!isKinokassa ? <LoginButton withIcon /> : null}
			</div>
			{isReleasePage ? <ReleaseItem setPoster={(poster) => setPoster(poster)} /> : null}
			{hasNeedShowBanners
				? (
					<div className="banners-slider kassa-container">
						<BannersSliderComponent setPoster={setPoster}/>
					</div>
				) : null}
		</header>
	)
}

export default Header;
