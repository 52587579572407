import httpRequestPromise from 'app/httpRequestPromise';
import { KINOKASSA_API_URL, SHARED_METRICS_ID } from 'app/constants/SettingsConstants';
import { AnalyticsData } from 'app/types/common';

function appendGA(id: string) {
	const script = document.createElement('script');
	script.async = true;
	script.setAttribute('src', `//www.googletagmanager.com/gtag/js?id=${id}`);
	document.body.appendChild(script);

	window.dataLayer = window.dataLayer || [];
	function gtag() {
		window.dataLayer.push(arguments);
	}

	// @ts-ignore
	gtag('js', new Date());
	// @ts-ignore
	gtag('config', id);
}

function appendGTM(id: string) {
	(function(w, d, s, l, i) {
		w[l] = w[l] || [];
		// @ts-ignore
		w[l].push({
			'gtm.start': new Date().getTime(),
			event: 'gtm.js'
		});
		var f = d.getElementsByTagName(s)[0],
			j = d.createElement(s) as HTMLScriptElement,
			dl = l != 'dataLayer' ? '&l=' + l : '';
		j.async = true;
		j.src =
			'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
		f.parentNode?.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer', id);
}

export function appendYaBase() {
    (function(m, e, t, r, i, k, a) {
        m[i] = m[i] || function()
        {
            // @ts-ignore
            (m[i].a = m[i].a || []).push(arguments)
        };
        // @ts-ignore
        m[i].l = 1 * new Date();
        // @ts-ignore
        k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

    window.ym(SHARED_METRICS_ID, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,

        triggerEvent: true
    });
}

function appendYa(id) {
    window.ym(id, "init", {
        clickmap: false,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: false,

        triggerEvent: true
    });
}

export async function getAnalyticsRequest(currentCinemasIds: number[]) {
    try {
        const response: AnalyticsData[] = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/cinema/analytics`);

        const newAnalyticsClientIds = response.reduce((acc, {cinema_id, google_analytics, google_tag_manager, yandex_metrics}) => {
            if (currentCinemasIds.includes(cinema_id)) {
                yandex_metrics?.client_id && acc.YA.add(yandex_metrics.client_id);
                google_analytics?.client_id && acc.GA.add(google_analytics.client_id);
                google_tag_manager?.client_id && acc.GTM.add(google_tag_manager.client_id);
            }

            return acc;
        }, {
            YA: new Set<string>(),
            GA: new Set<string>(),
            GTM: new Set<string>(),
        });

        newAnalyticsClientIds.YA.forEach((id) => {
            if (!window.currentYandexCounters?.delete(id)) {
                appendYa(id);
            }
        });

        window.currentYandexCounters?.forEach((id) => {
            window[`yaCounter${id}`]?.destruct();
            delete window[`yaCounter${id}`];
        });

        newAnalyticsClientIds.GA.forEach((id) => appendGA(id));
        newAnalyticsClientIds.GTM.forEach((id) => appendGTM(id));

        window.currentYandexCounters = newAnalyticsClientIds.YA;
    } catch (error) {
        console.log(error);
    }
}
