import { setYaReachGoal } from "app/selectors/Helpers";
import Address from "app/components/contacts/Address";
import Phones from "app/components/contacts/Phones";
import { memo } from "react";
import { useIsMobile } from "app/hooks";
import { ContactsProps } from "app/containers/header/ContactsContainer";

function Contacts({
	cinema,
	addresses,
	phones,
	isSeveralCinemasInCity,
	hasTitle = false,
}: ContactsProps) {
	const isMobile = useIsMobile(true);
	const handleIconClick = (event) => {
		const {icon} = event.currentTarget.dataset;

		setYaReachGoal(`${icon}-click`);
	}

	return (
		<>
			<Phones
				oldPhones={cinema.phoneNumbers}
				newPhones={phones}
				onClick={handleIconClick}
				isMobileMenu={isMobile}
			/>
			<Address
				cinema={cinema}
				addresses={addresses}
				hasTitle={hasTitle}
				isSeveralCinemasInCity={isSeveralCinemasInCity}
				onClick={handleIconClick}
			/>
		</>
	)
}

export default memo(Contacts);