import * as styles from 'css/proculture-label.module.scss';
import * as badge from 'css/release-badge.module.scss';
import cn from 'classnames'
import { useSelector } from 'react-redux';
import { getIsMonitor, getWithNewDesign } from 'app/selectors/AppSelectors';
import { getContrastColorForAcccent } from 'app/selectors/Helpers';
import { useIsMobile } from 'app/hooks';

function ProcultureLabel({isReleasePage}: {isReleasePage?: boolean}) {
	const withNewDesign = useSelector(getWithNewDesign);
	const isMobile = useIsMobile();
	const isMonitor = useSelector(getIsMonitor);
	const colorText = !isReleasePage && !isMonitor && withNewDesign && isMobile ? getContrastColorForAcccent() : '';

	const classes = cn('text text--size-12', {
		[`${styles.releasePageLabel} ${badge.releaseBadge} ${badge.badgeOpacityAccent}`]: isReleasePage,
		[styles.procultureLabel]: !isReleasePage,
		[styles.procultureLabelOld]: !withNewDesign
	});

	return (
		<div className={classes}>
			<img src="/img/pushkin.png" alt="pushkin_card" />
			<span style={{color: colorText}}>{i18n.t('PushkinCard')}</span>
		</div>
	)
}

export default ProcultureLabel;