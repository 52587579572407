import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import CitySearchInput from 'app/components/CitySelect/CitySearchInputComponent';
import { closeIcon } from 'app/constants/IconsConstants';
import { getBusinessDay, getFilteredCities, getHasNeedUseRoutePrefix } from 'app/selectors/AppSelectors';
import { hidePopup, setCityFilter, saveNewCurrentCityId, setSelectedDay } from 'app/actions/AppActions';

const CitiesListSelectComponent = () => {
	const dispatch = useDispatch();

	const { push, location } = useHistory();

	const citiesData = useSelector(getFilteredCities);
	const hasNeedUseRoutePrefix = useSelector(getHasNeedUseRoutePrefix);

	const handleHideButtonClick = () => {
		batch(() => {
			dispatch(setCityFilter(''));
			dispatch(hidePopup());
		})
	};

	const handleClick = ({currentTarget}) => {
		const newCityId = Number(currentTarget.dataset.cityId);

		batch(() => {
			dispatch(saveNewCurrentCityId(newCityId));
			dispatch(hidePopup());
			dispatch(setSelectedDay(getBusinessDay()));
		})

		if (location.pathname !== '/') {
			push(`/${hasNeedUseRoutePrefix ? newCityId : ''}`);
		}
	};

	const setFilter = useCallback((value: string) => {
		dispatch(setCityFilter(value));
	}, []);

	return (
		<div className="cities-list-select">
			<div className="cities-list-select__header">
				<h1 className="text text--size-16">{i18n.t('ChooseYourCity')}</h1>
				<KeydownEnterButton
					className="cities-list-select__hide-btn"
					onClick={handleHideButtonClick}
				>
					{closeIcon}
				</KeydownEnterButton>
			</div>
			<CitySearchInput placeholder={i18n.t('Location')} setFilter={setFilter} />
			<div className="cities-list-select__list">
				{citiesData.map(({id, title, region}) => (
					<KeydownEnterLink
						key={id}
						tabIndex={0}
						type="plain"
						data-city-id={id}
						onClick={handleClick}
						className="cities-list-select__city-item"
					>
						<>
							<span>{title}</span>
							{region
								? (
									<span className="cities-list-select__city-item--region text text--size-14">
										{region}
									</span>
								) : null}
						</>
					</KeydownEnterLink>
				))}
			</div>
		</div>
	);
};

export default CitiesListSelectComponent;
