import { TABLET_MAX_SIZE } from "app/constants/AppConstants";
import { useEffect, useRef, useState } from "react";

export const usePrevious = <T,>(value: T) => {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
};

export const useWindowSize = () => {
	const [width, setWidth] = useState({
		viewportWidth: window.outerWidth,
		windowWidth: window.innerWidth,
	});

	useEffect(() => {
		const handleWindowResize = () => {
			setWidth({
				viewportWidth: window.outerWidth,
				windowWidth: window.innerWidth,
			});
		};

		window.addEventListener('resize', handleWindowResize);

		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	return width;
};

export const useIsMobile = (isCssBreakpoint = false) => {
	const { viewportWidth, windowWidth } = useWindowSize();

	return [viewportWidth, windowWidth].some(width => isCssBreakpoint
		? width < TABLET_MAX_SIZE
		: width <= TABLET_MAX_SIZE);
};

export const useMobileViewBreakPoint = (mobileViewBreakpoint: number) => {
	const { windowWidth } = useWindowSize();

	const [isMobileView, setIsMobileView] = useState(windowWidth <= mobileViewBreakpoint)

	useEffect(() => {
		setIsMobileView(windowWidth <= mobileViewBreakpoint);
	}, [windowWidth, mobileViewBreakpoint]);

	return { isMobileView };
}

export const useWillMount: typeof useEffect = (effect) => {
	const willMount = useRef(true);

	if (willMount.current) effect();

	willMount.current = false;
}

export const useSwipe = (onSwipedStart, onSwipedEnd) => {
	let touchStartX = 0;
	let touchEndX = 0;

	const handleTouchStart = (e) => {
		touchStartX = e.touches[0].clientX;
	};

	const handleTouchEnd = (e) => {
		touchEndX = e.changedTouches[0].clientX;
		handleSwipe();
	};

	const handleSwipe = () => {
		const swipeDistance = touchEndX - touchStartX;

		if (swipeDistance > 50) {
			onSwipedEnd();
		} else if (swipeDistance < -50) {
			onSwipedStart();
		}
	};

	useEffect(() => {
		window.addEventListener('touchstart', handleTouchStart);
		window.addEventListener('touchend', handleTouchEnd);

		return () => {
			window.removeEventListener('touchstart', handleTouchStart);
			window.removeEventListener('touchend', handleTouchEnd);
		};
	}, []);
};
