import React from 'react';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import SocialLinksComponent from 'app/components/SocialLinksComponent';
import { setYaReachGoal } from 'app/selectors/Helpers';
import {
	KINOGRADVIDNOE_CINEMA_ID,
	AVRORA_CINEMA_ID,
	KINOGRAD_VP_CINEMA_ID,
} from 'app/constants/CinemaConstants';
import { isFondKino } from 'app/selectors/AppSelectors';
import { Socials } from 'app/types/common';
import { FooterProps } from 'app/containers/FooterContainer';
import { isEnabledDetails, isEnabledSalesRules } from 'app/constants/AppConstants';
import FooterContactsComponent from 'app/components/FooterContactsComponent';

export default class FooterComponent extends React.Component<FooterProps> {
	handleClickItemMenu = (event) => {
		const {isCustomCinemaLink, linkTitle} = event.target.dataset;

		if (isCustomCinemaLink) {
			setYaReachGoal('open-section', {section: linkTitle});
		}

		document.body.scrollTop = document.documentElement.scrollTop = 0;
	};

	handleSemiblindClick = () => {
		const {setSemiblind, setFontSize} = this.props;

		localStorage.setItem('colorTheme', 'blackOnWhite');
		localStorage.setItem('showImages', 'imageOn');
		localStorage.setItem('fontSize', '16');
		localStorage.setItem('fontType', 'noSerif');
		localStorage.setItem('lineHeight', 'line_1_5');
		localStorage.setItem('letterSpacing', 'letter-spacing1');

		setSemiblind(true);
		setFontSize(16);
	};

	renderMenuLinks = () => this.props.links.map(({href, title, isCustomCinemaLink, sub }, index) => (
		<KeydownEnterLink
			to={href}
			key={index}
			className="footer__links-item"
			data-is-custom-cinema-link={isCustomCinemaLink}
			data-link-title={sub && sub.length ? sub[0].title : title}
			onClick={this.handleClickItemMenu}
		>
			{title}
		</KeydownEnterLink>
	))

	renderPaymentInfoSection = () => {
		const {
			cinema: {payment: {card: paymentCards}},
			prefix,
		} = this.props;

		return (
			<React.Fragment>
				<h2 className="footer__column-title">{i18n.t('PaymentMethods')}</h2>
				<div className="footer__payment-cards-wrapper">
					{paymentCards.map((card, index) => (
						<img
							key={index}
							alt={`${card}`}
							className="footer__badge footer__badge--payment"
							src={`/img/badge/card/${card}.svg`}
						/>
					))}
				</div>
				{(isEnabledSalesRules || isEnabledDetails) && (
					<div className="footer__sales-rules-links-wrapper">
						{i18n.t('YouMightKnowWith')}
						{' '}
						{
							isEnabledSalesRules && (
								<KeydownEnterLink to={`${prefix}/sale-rules`} className="footer__links-item">
									{i18n.t('paymentConditions')}
								</KeydownEnterLink>
							)
						}
						{isEnabledDetails && isEnabledSalesRules && ` ${i18n.t('and')} `}
						{
							isEnabledDetails && (
								<KeydownEnterLink to={`${prefix}/details`} className="footer__links-item">
									{i18n.t('requisites_many')}
								</KeydownEnterLink>
							)
						}
					</div>
				)}
			</React.Fragment>
		);
	}

	renderSocialLinks = (socials: Partial<Socials>) => (
		<div className="footer__socials-wrapper">
			<h2 className="footer__column-title">{i18n.t('SocialNetwork')}</h2>
			<SocialLinksComponent
				className="footer__social"
				linksData={socials}
				isColorIcons
				showTitles={false}
			/>
		</div>
	);

	renderAppsLinks = () => {
		const {apps: {appstore, googleplay}} = this.props;
		const appsLinksData = [
			{
				linkId: 'appstore',
				href: appstore,
			},
			{
				linkId: 'googleplay',
				href: googleplay,
			},
		];

		return (
			<div className="footer__app-links-wrapper">
				{appsLinksData.map(({linkId, href}) => href ? (
					<KeydownEnterLink
						key={linkId}
						type="plain"
						style={{backgroundImage: `url('/img/badge/app/${linkId}.svg')`}}
						className="footer__badge footer__badge--app-link"
						href={href}
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => setYaReachGoal(`${linkId}-click`)}
					/>
				) : null)}
			</div>
		);
	}

	render() {
		const {
			cinemaID,
			cinema: {
				payment,
			},
			socials,
			prefix,
			isSemiblind,
			isSeveralCinemasInCity,
			isMobile
		} = this.props;

		return (
			<footer className="footer">
				<div className="container footer__container footer__container--top">
					<div className="footer__logo-controls-section">
						<img className="footer__logo" src="/img/logo.svg" alt={i18n.t('Logo')} />
						{this.renderAppsLinks()}
					</div>
				</div>
				<div className="container footer__container">
					<div className="footer__main-section">
						<div className="footer__column">
							{isFondKino && (
								<img className="footer__logo" src="/img/logo.svg" alt={i18n.t('Logo')} />
							)}
							<h2 className="footer__column-title">{i18n.t('Menu')}</h2>
							<div className="footer__links">
								{this.renderMenuLinks()}
							</div>
						</div>
						<div className="footer__column footer__column--double-space">
							{payment.active && this.renderPaymentInfoSection()}
							{!_.isEmpty(socials) && this.renderSocialLinks(socials)}
							{cinemaID === KINOGRADVIDNOE_CINEMA_ID && (
								<a
									className="footer__links-item"
									href="/resources/rules.pdf"
									rel="noopener noreferrer"
									download
									style={{
										display: 'inline-block',
										marginTop: '16px',
									}}
								>
									{i18n.t('RulesOfCinema')}
								</a>
							)}
						</div>
						<div className="footer__column">
							<FooterContactsComponent
								cinemaID={cinemaID}
								isSeveralCinemasInCity={isSeveralCinemasInCity}
								prefix={prefix}
							/>
						</div>
					</div>
					<div className="footer__bottom-section">
						<div className="footer__copyright-wrapper">
							<span className="footer__copyright">© {new Date().getFullYear()}. {i18n.t('AllRightsReserved')}</span>
							<span>
								{`${i18n.t('DevelopedAt')} `}
								<KeydownEnterLink
									type="plain"
									className="footer__links-item"
									href="https://kinoplan.ru/"
									target="_blank"
									rel="noopener noreferrer"
								>
									{i18n.t('Kinoplane')}
								</KeydownEnterLink>
							</span>
						</div>
						{!isSemiblind && (cinemaID !== KINOGRAD_VP_CINEMA_ID || isMobile) && (
							<KeydownEnterButton
								className="footer__view-button view-button"
								onClick={this.handleSemiblindClick}
							>
								{i18n.t('VersionForTheVisuallyImpaired')}
							</KeydownEnterButton>
						)}
					</div>
					{cinemaID !== AVRORA_CINEMA_ID
						? <p className="footer__ads-public-notice">
							{i18n.t('StartOfAdvertisingBlock')}.<br/>
							{i18n.t('CheckDirectionAtCinema')}.
						</p>
						: null
					}
				</div>
			</footer>
		);
	}
}
