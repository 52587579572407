import { memo } from "react"
import * as style from 'css/seance.module.scss';
import Flex from "app/ui/Flex/Flex";
import { convertToRubles, getTitles } from "app/selectors/Helpers";
import WidgetOverlay from 'app/containers/WidgetOverlayContainer';
import { DailySeance } from "app/types/common";
import { vipHallIcon } from "app/constants/IconsConstants";

interface IProps {
	seance: DailySeance,
	releaseId: number,
	ramblerReleaseId: number | null,
	seanceDisplaySettings: {
		format: boolean,
		hall: boolean,
		price: boolean,
		vip_badge: boolean,
	},
	page: string,
	currencySymbol: string,
	isMonitor: boolean,
}

const Seance = ({
	seance: {
		start_date_time,
		price: {
			min: minPrice,
			max: maxPrice,
		},
		id,
		formats,
		hall,
		cinema_id,
	},
	seanceDisplaySettings: {
		format: isSettingsFormat,
		hall: isSettingsHall,
		price: isSettingsPrice,
		vip_badge: isSettingsVip,
	},
	releaseId,
	ramblerReleaseId,
	page,
	currencySymbol,
	isMonitor,
}: IProps) => {
	const startTime = moment.parseZone(start_date_time).format('HH:mm');
	const isShowVip = hall.is_vip && isSettingsVip;
	const seanceMinPrice = convertToRubles(minPrice);
	const seanceMaxPrice = convertToRubles(maxPrice);
	const hasMaxPrice = seanceMaxPrice > seanceMinPrice;

	const renderFormats = (seanceFormats: DailySeance['formats']) => {
		const formatTitles = getTitles(seanceFormats);

		if (formatTitles.includes('IMAX 3D')) {
			return <img src={'/img/imax3d.svg'} alt={'IMAX 3D'} />;
		} else if (formatTitles.includes('IMAX')) {
			return <img src={'/img/imax.svg'} alt={'IMAX'} />;
		}

		return formatTitles;
	};

	return (
		<div className="seance-item">
			<WidgetOverlay
				cinemaId={cinema_id}
				releaseId={releaseId}
				seanceId={id}
				startTime={start_date_time}
				ramblerReleaseId={ramblerReleaseId}
				page={page}
			/>
			<Flex classes={style.seance} direction="column" gap="8px">
				<div className={`${style.seanceTime} text text--size-26`}>
					{startTime}
				</div>
				{isSettingsFormat || isSettingsPrice
					? (
						<div className={`${style.seanceFormats} text text--size-12`}>
							{isSettingsFormat &&
								<span>{isMonitor ? renderFormats(formats) : getTitles(formats)}</span>
							}
							{isSettingsPrice &&
								<span>
									{hasMaxPrice
										? `${i18n.t('of')} ${seanceMinPrice}`
										: seanceMinPrice}&thinsp;{currencySymbol}
								</span>
							}
						</div>
					) : null}
				{isShowVip || isSettingsHall
					? (
						<div className={`${style.seanceDesc} text text--size-12`}>
							{isSettingsHall ? `${i18n.t('Hall')} ${hall.title}` : null}
							{isShowVip ? <>{vipHallIcon} VIP</> : null}
						</div>
					) : null}
			</Flex>
		</div>
	)
}

export default memo(Seance);