import { IMenu } from "app/types/common";
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import Menu from 'app/containers/MenuContainer';
import CitySelect from 'app/containers/CitySelect/CitySelectContainer';
import ReleaseItem from 'app/components/release_item/ReleaseItem';
import {CSSProperties, useEffect, useState} from "react";
import {useLocation} from "react-router";
import cn from 'classnames';
import { useSelector } from "react-redux";
import LoginButton from "app/containers/LoginButtonContainer";
import { getTransformedBanners } from "app/modules/banners/redux/bannersSelectors";
import BannersSliderComponent from "app/modules/banners/components/BannersSliderComponent";

interface IProps {
	isCitySelectShown: boolean,
	menuLinks: IMenu[],
	hasBanners: boolean,
	isSemiblind: boolean,
	isKinokassa: boolean,
}

function MobileHeader({ isCitySelectShown, menuLinks, hasBanners, isSemiblind, isKinokassa }: IProps) {
	const banners = useSelector(getTransformedBanners);

	const [poster, setPoster] = useState('');

	const isReleasePage = useLocation().pathname.includes('release');

	const hasNeedShowBanners = !isReleasePage && hasBanners && !isSemiblind;

	useEffect(() => {
			if (hasNeedShowBanners && banners.length && !isReleasePage) {
				setPoster(banners[0].original);
			}
		}, [banners, hasNeedShowBanners, isReleasePage]);

	return (
		<header
			id="header"
			className={cn('header-mobile kassa-container', {'header-mobile--full': isReleasePage || hasNeedShowBanners})}
			style={{'--poster-url': `url(${poster})`} as CSSProperties}
		>
			<div className="header-mobile__logo">
				<KeydownEnterLink
					type="plain"
					href="/"
					className="header__logo"
					rel="noopener noreferrer"
				>
					<img className="header__logo-img" src="/img/logo.svg" alt={i18n.t('Logo')} />
				</KeydownEnterLink>
				<div className="header-mobile__menu">
					{!isKinokassa ? <LoginButton withIcon /> : null}
					<Menu.Mobile links={menuLinks} />
				</div>
			</div>
			{isCitySelectShown && <CitySelect.Kinokassa isDesktopView={false} />}
			{hasNeedShowBanners
				? (
					<div className="banners-slider">
						<BannersSliderComponent setPoster={setPoster}/>
					</div>
				)
				: null}
			{isReleasePage ? <ReleaseItem setPoster={(poster) => setPoster(poster)} /> : null}
		</header>
	)
}

export default MobileHeader;
