import httpRequestPromise from 'app/httpRequestPromise';
import { KINOKASSA_API_URL } from 'app/constants/SettingsConstants';
import { setBanners } from 'app/modules/banners/redux/bannersActions';

export const getBannersRequest = (cinemaId: number | string | null, cityId: number | string) => async dispatch => {
	try {
		const banners = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/banners?${
			cinemaId ? `cinema_id=${cinemaId}` : `city_id=${cityId}`
		}`);

		dispatch(setBanners(banners));
	} catch (error) {
		dispatch(setBanners([]));
	}
};