import { IBannersReducer } from 'app/modules/banners/bannersTypes';
import { ReduxTypes } from 'app/store/redux';
import * as constants from 'app/modules/banners/redux/bannersConstants';
import * as actions from 'app/modules/banners/redux/bannersActions';

const initialState: IBannersReducer = {
	banners: [],
	isFirstBannerLoaded: false
};

const bannersReducer = (
	state: IBannersReducer = initialState,
	action: ReturnType<ReduxTypes.InferValueTypes<typeof actions>>
): IBannersReducer => {
	switch (action.type) {
		case constants.SET_BANNERS:
			return {
				...state,
				banners: action.payload
			}
		case constants.RESET_BANNERS:
			return {
				...state,
				banners: []
			}
		case constants.SET_IS_FIRST_BANNER_LOADED:
			return {
				...state,
				isFirstBannerLoaded: action.payload
			}
		default:
			return state;
	}
}

export default bannersReducer