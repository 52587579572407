import { TransformedBannerType } from "app/modules/banners/bannersTypes";
import { isMobileSelector } from "app/selectors/AppSelectors";
import { IReduxState } from "app/types/state";
import { createSelector } from "reselect";

export const getBanners = (state: IReduxState) => state.bannersReducer.banners;
export const getIsFirstBannerLoaded = (state: IReduxState) => state.bannersReducer.isFirstBannerLoaded;

export const getTransformedBanners = createSelector(
	[getBanners, isMobileSelector],
	(banners, isMobile) => banners.reduce<TransformedBannerType[]>((acc, {id, title, image, url, advertiser_desktop, advertiser_mobile}) => {
		if (image.desktop && image.mobile) {
			acc.push({
				id,
				title,
				original: isMobile ? image.mobile : image.desktop,
				bulletClass: 'bullets',
				url: isMobile ? url.mobile : url.desktop,
				originalClass: url.mobile || url.desktop ? 'slider__image--with-pointer' : '',
				advertiser: isMobile ? advertiser_mobile : advertiser_desktop
			});
		}

		return acc;
	}, []),
);

export const getHasBanners = (state: IReduxState) => !!getTransformedBanners(state).length;
