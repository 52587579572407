import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AfishaComponent from 'app/components/afisha/AfishaComponent';
import NewsContainer from 'app/containers/NewsContainer';

interface IProps { addedPath: string; }

const MonitorVipPageComponent = ({ addedPath }: IProps) => (
	<Switch>
		<Redirect exact from={`${addedPath}/vip`} to={`${addedPath}/vip/afisha`} />
		<Route path={`${addedPath}/vip/afisha`}>
			<AfishaComponent isVip />
		</Route>
		<Route path={`${addedPath}/vip/news`}>
			<NewsContainer isVip />
		</Route>
	</Switch>
);

export default MonitorVipPageComponent;
