import { useEffect } from "react";

export const useSiteReloading = () => {
	useEffect(() => {
		let timeoutId: number;
		let millisecondsToMidnight: number;

		// если сайт открыли менее чем за час до полуночи, то будем перезагружать его уже на следующий день
		if (moment({hours: 23}).isBefore(moment())) {
			millisecondsToMidnight = moment().add(1, "d").endOf("day").diff(moment())
		} else {
			millisecondsToMidnight = moment().endOf("day").diff(moment());
		}

		timeoutId = setTimeout(() => {
			location.reload();
		}, millisecondsToMidnight) as any as number

		return () => {
			clearTimeout(timeoutId);
		}
	}, []);
}