export enum SortTypes {
	DEFAULT = "DEFAULT",
	CLOSEST_SEANCES = "CLOSEST_SEANCES",
	PRICE = "PRICE"
}

export const enum FiltersPage {
	AFISHA = "AFISHA",
	RELEASE = "RELEASE",
	SOON = "SOON",
	DAILY_SEANCES = "DAILY_SEANCES",
	CINEMA_DAILY_SEANCES = "CINEMA_DAILY_SEANCES",
	PUSHKIN_CARD = "PUSHKIN_CARD",
}

export const enum FilterValueType {
	LOCAL = "LOCAL",
	MAIN = "MAIN",
}

// Значения FILTER_PANEL_ELEMENTS используются в качестве ключей в стейте, а также как идентификаторы элементов на панели фильтров
export const FILTER_PANEL_ELEMENTS = {
	GENRE: "genres",
	AGE: "ageRatings",
	FEATURES: "features",
	CALENDAR: "calendar",
	CALENDAR_MOBILE: "calendar_mobile",
	PUSHKIN_CARD: "pushkinCard",
	CINEMA: "cinemas",
	SORTING: "sortType",
	MOBILE_ALL_FILTERS: "mobile_all_filters"
} as const;

export const MULTISELECTABLE_FILTERS = [
	FILTER_PANEL_ELEMENTS.GENRE,
	FILTER_PANEL_ELEMENTS.AGE,
	FILTER_PANEL_ELEMENTS.FEATURES,
	FILTER_PANEL_ELEMENTS.CINEMA,
] as const;

export interface IFilterValue {
	id: string;
	title: string;
	filterKey: keyof IFiltersState["selectedValues"],
}

export interface IFiltersState {
	selectedValues: {
		genres: Record<string, IFilterValue> | null;
		ageRatings: Record<string, IFilterValue> | null;
		features: Record<string, IFilterValue> | null;
		cinemas: Record<string, IFilterValue> | null;
		pushkinCard: boolean;
		sortType: SortTypes;
	},
	localSelectedValues: IFiltersState["selectedValues"],
	page: FiltersPage | null;
	acriveElementId: FilterPanelElementsType | null;
}

export type FilterPanelElementsType = typeof FILTER_PANEL_ELEMENTS[keyof typeof FILTER_PANEL_ELEMENTS];

export type FiltersOfReleasesList = Pick<
	IFiltersState["selectedValues"], "ageRatings" | "features" | "genres" | "pushkinCard"
>