import React, { useCallback, useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import parse from 'html-react-parser';
import Spinner from 'app/components/SpinnerComponent';
import { setYaReachGoal } from 'app/selectors/Helpers';
import { closeIcon } from 'app/constants/IconsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { showPopup, hidePopup } from 'app/actions/AppActions';
import ReactImageGallery from 'react-image-gallery';
import { getIsFirstBannerLoaded, getTransformedBanners } from 'app/modules/banners/redux/bannersSelectors';
import { setIsFirstBannerLoaded } from 'app/modules/banners/redux/bannersActions';
import * as styles from "css/imageGallery.module.scss";

const BannersSliderComponent = ({setPoster}: {setPoster?: (value: string) => unknown}) => {
	const dispatch = useDispatch();

	const banners = useSelector(getTransformedBanners);
	const isFirstBannerLoaded = useSelector(getIsFirstBannerLoaded);

	const imageGalleryRef = useRef<ReactImageGallery | null>(null);

	const [currentAdvertisementIndex, setCurrentAdvertisementIndex] = useState(0);

	useEffect(() => {
		if (banners.length) {
			setYaReachGoal(
				'banner-show',
				{
					bannerID: banners[0].id,
					bannerTitle: banners[0].title
				},
			);
		}
	}, [])

	const handleImageClick = useCallback(() => {
		if (!imageGalleryRef.current) return;

		const current = imageGalleryRef.current.getCurrentIndex();
		const image = banners[current];
		if (image.url !== null) {
			window.open(image.url);
		}
	}, [banners]);

	const handleSlideChange = useCallback((currentIndex: number) => {
		setYaReachGoal(
			'banner-show',
			{
				bannerID: banners[currentIndex].id,
				bannerTitle: banners[currentIndex].title
			},
		);

		if (setPoster) {
			setPoster(banners[currentIndex].original);
		}

		setCurrentAdvertisementIndex(currentIndex);
	}, [banners, setPoster]);

	const handleImageLoad = useCallback(() => {
		if (!isFirstBannerLoaded) {
			dispatch(setIsFirstBannerLoaded(true))
		}
	}, [isFirstBannerLoaded]);

	const handleAboutAdvertiserClick = () => {
		imageGalleryRef.current?.pause();

		const onClosePopup = () => {
			imageGalleryRef.current?.play();
			dispatch(hidePopup());
		};

		dispatch(showPopup({
			popupContentNode: (
				<div className={styles.advertisementsPopup}>
					<div className={styles.advertisementsPopupHeader}>
						<h5 className={styles.advertisementsPopupTitle}>{i18n.t('InformationAboutAdvertiser')}</h5>
						<div
							className="popup__exit" onClick={onClosePopup}
						>
							{closeIcon}
						</div>
					</div>
					<div className={styles.advertisementsPopupContent}>
						{parse(banners[currentAdvertisementIndex].advertiser!)}
					</div>
				</div>
			),
			withCloseButton: false,
			onClosePopup
		}));
	}

	const renderAboutAdvertiserLink = () => {
		return banners[currentAdvertisementIndex] && banners[currentAdvertisementIndex].advertiser ? (
			<button
				className={styles.sliderAboutAdvertisementLink} onClick={handleAboutAdvertiserClick}
			>
				<span>{i18n.t('Advertisement')}</span>
				<img src="/img/checkmark-warning.svg" alt="checkmark" />
			</button>
		) : null;
	}

	const style = isFirstBannerLoaded ? '' : 'hidden';

	return (
		<div className={styles.slider}>
			{ isFirstBannerLoaded ? renderAboutAdvertiserLink() : <Spinner banners /> }
			<ImageGallery
				ref={imageGalleryRef}
				items={banners}
				showFullscreenButton={false}
				showPlayButton={false}
				showThumbnails={false}
				showBullets={banners.length > 1}
				autoPlay
				slideInterval={10000}
				lazyLoad
				preventDefaultTouchmoveEvent
				onImageLoad={handleImageLoad}
				onClick={handleImageClick}
				additionalClass={style}
				onSlide={handleSlideChange}
			/>
		</div>
	);
}

export default BannersSliderComponent;