import classnames from "classnames";
import { arrowIcon } from "app/constants/IconsConstants";
import { memo, useMemo, useRef, useState } from "react";
import { getBusinessDay } from "app/selectors/AppSelectors";
import { formatMoment } from "app/selectors/Helpers";
import { getSelectedDay } from "app/selectors/AppSelectors";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedDay } from 'app/actions/AppActions';
import { setActiveElementId } from "../redux/actions";
import { getMonthByDay } from "app/modules/filters/helpers";
import { useHistory } from "react-router-dom";

interface IProps {
	seanceDates?: string[];
	onDateChange?: (date: string) => void;
}

const Calendar = ({
	seanceDates,
	onDateChange,
}: IProps) => {
	const dispatch = useDispatch();

	const history = useHistory();

	const selectedDay = useSelector(getSelectedDay);

	const currentBusinesDayRef = useRef(getBusinessDay());

	const [openedMonth, setOpenedMonth] = useState(
		getMonthByDay(currentBusinesDayRef.current, selectedDay)
	);

	const seanceDatesByMonth = useMemo(() => {
		const grouped: {
			values: Record<number, {
				seanceDates: Record<string, string>;
				monthName: string;
			}>;
			length: number;
		} = {
			values: {},
			length: 0
		};

		seanceDates?.forEach((date) => {
			const momentDate = moment(date);
			const monthOfDay = getMonthByDay(currentBusinesDayRef.current, momentDate);

			if (!grouped.values[monthOfDay]) {
				grouped.values[monthOfDay] = {
					seanceDates: {
						[date]: date
					},
					monthName: momentDate.format("MMMM")
				}

				grouped.length = Math.max(grouped.length || monthOfDay)
			} else {
				grouped.values[monthOfDay].seanceDates[date] = date;
			}
		})

		return grouped;
	}, [seanceDates]);

	const calendarForCurrentMonth = useMemo(() => {
		const firstDayOfCurrentMonth = moment(currentBusinesDayRef.current).add(openedMonth, "month").startOf("month");

		const daysInCurrentMonth = firstDayOfCurrentMonth.daysInMonth();

		const firstDaysOfWeekInMonth = firstDayOfCurrentMonth.weekday() - 1;

		return Array.from({length: 35}, (_, i) => {
			if (i >= firstDaysOfWeekInMonth && i < (firstDaysOfWeekInMonth + daysInCurrentMonth)) {
				const momentOfDay = moment(firstDayOfCurrentMonth).add(i - firstDaysOfWeekInMonth, "day");

				const formattedDay = formatMoment(momentOfDay);

				return {
					title: momentOfDay.date(),
					value: formattedDay,
				}
			} else {
				return {
					isEmptyDay: true
				}
			}
		})
	}, [openedMonth])

	return (
		<div className="calendar-popup">
			<div className="calendar-popup__header-wrapper">
				<span
					className={classnames("calendar-popup__icon calendar-popup__icon-left", {
						"calendar-popup__icon--disabled": openedMonth === 0
					})}
					onClick={() => setOpenedMonth((prev) => prev - 1)}
				>{arrowIcon}</span>
				<span className="calendar-popup__month-title">{moment(currentBusinesDayRef.current).add(openedMonth, "month").format("MMMM")}</span>
				<span
					className={classnames("calendar-popup__icon calendar-popup__icon-right", {
						"calendar-popup__icon--disabled": openedMonth === seanceDatesByMonth.length
					})}
					onClick={() => setOpenedMonth((prev) => prev + 1)}
				>{arrowIcon}</span>
			</div>
			<div className="calendar-popup__date-list">
				{moment.weekdaysMin().map((dayOfWeek) => (
					<span key={dayOfWeek} className="calendar-popup__day-of-week">{dayOfWeek}</span>
				))}
				{
					calendarForCurrentMonth.map(({isEmptyDay, title, value}, index) => (
						<div
							key={index}
							onClick={() => {
								if (value) {
									const date = moment(value);

									history.replace({search: `?date=${formatMoment(date)}`});

									dispatch(updateSelectedDay(date));
									onDateChange?.(value);
									dispatch(setActiveElementId());
								}
							}}
							className={classnames("calendar-popup__date-list-item", {
								"calendar-popup__date-list-item--empty": isEmptyDay,
								"calendar-popup__date-list-item--avaliable": value && seanceDatesByMonth.values[openedMonth]?.seanceDates[value],
								"calendar-popup__date-list-item--selected": selectedDay && value === formatMoment(selectedDay),
							})}>
								{title}
						</div>
					))
				}
			</div>
		</div>
	)
}

export default memo(Calendar);