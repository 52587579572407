import {connect} from 'react-redux';
import {updateSelectedDay} from 'app/actions/AppActions';
import {
	getIsMonitor,
	getSelectedDay,
	isFeedbackEnabled,
	isMobileSelector,
	getIsSeveralCinemasInCity,
	getIsKinokassa,
	getCurrentCinemaId,
} from 'app/selectors/AppSelectors';
import {getIsFirstBannerLoaded} from 'app/modules/banners/redux/bannersSelectors';
import MenuComponent from 'app/components/MenuComponent';
import KinokassaMenu from 'app/components/header/Menu';
import MenuMobile from 'app/components/header/MenuMobile';
import MenuCalendarComponent from 'app/components/MenuCalendarComponent';

const mapStateToProps = state => ({
	selectedDay: getSelectedDay(state),
	feedback: isFeedbackEnabled(state),
	isFirstBannerLoaded: getIsFirstBannerLoaded(state),
	isMobile: isMobileSelector(state),
	isMonitor: getIsMonitor(state),
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
	isKinokassa: getIsKinokassa(state),
	cinemaID: getCurrentCinemaId(state)
});

export default {
	KinokassaNav: connect(mapStateToProps, {updateSelectedDay}, null, {pure: false})(KinokassaMenu),
	Mobile: connect(mapStateToProps, {updateSelectedDay}, null, {pure: false})(MenuMobile),
	Navigation: connect(mapStateToProps, {updateSelectedDay}, null, {pure: false})(MenuComponent),
	Calendar: connect(mapStateToProps, {updateSelectedDay})(MenuCalendarComponent)
};
