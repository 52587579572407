import * as actions from 'app/constants/AppConstants';
import { KRASNODAR_CITY_ID } from 'app/constants/CinemaConstants';
import { KINOKASSA_API_URL } from 'app/constants/SettingsConstants';
import httpRequestPromise from 'app/httpRequestPromise';
import {
	normalizeResponseData, getDisplaySettingsOfCinemasForCurrentCity,
	normalizeContactsData, formatMoment,
} from 'app/selectors/Helpers';
import {
	getAllCinemas,
	getAllContacts,
	getCurrentCityId,
	getCurrentCityTitle,
	getCurrentCinemaId,
	getIsMonitor, getBusinessDay,
	getIsKinokassa,
} from 'app/selectors/AppSelectors';
import get from 'lodash/get';
import intersectionWith from 'lodash/intersectionWith';
import { createAction } from 'redux-actions';
import { getQualifiers } from 'app/actions/AfishaActions';
import { getPromos, resetPromos } from 'app/actions/PromoActions';
import { getNews, resetNews } from 'app/actions/NewsActions';
import { getCustomPagesRequest, resetPages } from 'app/actions/MenuActions';
import { getOffset } from 'app/selectors/NewsSelectors';
import { batch } from 'react-redux';
import { AboutCinemaItemType, Cinema } from 'app/types/common';
import { resetBanners } from 'app/modules/banners/redux/bannersActions';
import { getBannersRequest } from 'app/modules/banners/redux/bannersThunks';

export const showPopup = createAction(
	actions.SHOW_POPUP,
	popupOptions => ({popupOptions}),
);
export const hidePopup = createAction(actions.HIDE_POPUP);

const getAppDataRequest = createAction(
	actions.GET_APP_DATA_REQUEST,
);

const getAppDataSuccess = createAction(
	actions.GET_APP_DATA_SUCCESS,
	data => (data),
);

export const setAppError = createAction(
	actions.SET_APP_ERROR,
	(code) => ({code}),
);

export const setSelectedDay = createAction(
	actions.SET_SELECTED_DAY,
	selectedDay => ({selectedDay}),
);

const setContacts = createAction(
	actions.SET_CONTACTS,
	contacts => ({contacts}),
);

export const resetCurrentCinemaInfo = createAction(
	actions.RESET_CURRENT_CINEMA_INFO,
);

const setAboutCinema = createAction(
	actions.SET_ABOUT_CINEMA,
	aboutCinema => ({aboutCinema}),
);

const setSeveralCinemasAboutData = createAction(
	actions.SET_SEVERAL_CINEMAS_ABOUT_DATA,
	severalCinemasAboutData => ({severalCinemasAboutData}),
);

const setHalls = createAction(
	actions.SET_HALLS,
	halls => ({halls}),
);

export const setIsMobile = createAction(
	actions.SET_IS_MOBILE,
	(isMobile) => (isMobile),
);

export const setSemiblind = createAction(
	actions.SET_SEMIBLIND,
	(isSemiblind) => (isSemiblind),
);

export const setFontSize = createAction(
	actions.SET_FONT_SIZE,
	(fontSize) => (fontSize),
);

export const setNewCurrentCinemaData = createAction(
	actions.SET_NEW_CURRENT_CINEMA_DATA,
	(cinema, alert) => ({cinema, alert}),
);

export const setCityFilter = createAction(
	actions.SET_CITY_FILTER,
	(cityFilter) => ({cityFilter}),
);

export const setCurrentCityId = createAction(
	actions.SET_CURRENT_CITY_ID,
	(newId) => ({newId}),
);

export const setIsUserAuth = createAction(
	actions.SET_IS_USER_AUTH,
	isUserAuth => ({isUserAuth}),
);

export const setIsWidgetInKioskMode = createAction(
	actions.SET_IS_WIDGET_IN_KIOSK_MODE,
	isWidgetInKioskMode => ({isWidgetInKioskMode}),
);

export const setCountUnreadNotifications = createAction(
	actions.SET_COUNT_UNREAD_NOTIFICATIONS,
	countUnreadNotifications => ({countUnreadNotifications}),
);

const updateCurrentCinema = (dispatch, getState) => {
	const state = getState();
	const allCinemas = getAllCinemas(state);
	const cityId = getCurrentCityId(state);
	const currentCinemaId = getCurrentCinemaId(state);

	if (allCinemas.length || currentCinemaId) {
		const allContacts = getAllContacts(state);
		const currentCityTitle = getCurrentCityTitle(state);
		const cinemaID = currentCinemaId || allCinemas.filter(cinema => cinema.city_id === cityId)[0].id;

		const newCurrentCinema = (_.findWhere(allCinemas, {id: cinemaID}) || {}) as Cinema;
		const newCurrentCinemaAlertMessage = newCurrentCinema.alert;
		const newCurrentCinemaContacts = (_.chain(allContacts)
			.findWhere({cinema_id: cinemaID}))
			.mapObject((value, key) => (
				key === 'phones' || !Array.isArray(value) ? value : normalizeResponseData(value)
			)).value();

		dispatch(setNewCurrentCinemaData({...newCurrentCinema, city: currentCityTitle}, newCurrentCinemaAlertMessage));
		dispatch(resetCurrentCinemaInfo());
		dispatch(setContacts(newCurrentCinemaContacts));
	}
};

export const saveNewCurrentCityId = (cityId) => (dispatch) => {
	localStorage.setItem('currentCityId', cityId);

	dispatch(setCurrentCityId(cityId));
	dispatch(setCityFilter(''));
};

export const getAppData = (cinemaId) => async (dispatch) => {
	dispatch(getAppDataRequest());

	try {
		let response = await httpRequestPromise(
			`${KINOKASSA_API_URL}/api/v2/app?with_cities=true&with_contacts=true&cinema_id=${cinemaId}`);
		const {cinemas, cities, branding, modules, contacts, country} = response;
		const normalizeContacts = normalizeContactsData(contacts, cinemaId);
		const currentCinema = cinemas.find(cinema => cinema.id === cinemaId);
		const currentCityOfCinema = cities.length ? cities.filter(city => city.id === currentCinema.city_id) : [];

		const cinema = cinemas.length
			? {
				..._.omit(currentCinema, 'alert'),
				city: currentCityOfCinema[0].title
			}
			: {};

		dispatch(setContacts(normalizeContacts));
		dispatch(getAppDataSuccess({
			cinema,
			modules,
			alert: cinemas.length ? currentCinema.alert : null,
			branding,
			allCinemas: [currentCinema],
			allCities: currentCityOfCinema,
			allContacts: contacts,
			country,
		}));

		return {cityId: currentCityOfCinema[0].id};

	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}

	return null;
};

export const getAboutCinemaRequest = (currentCinemaID) => async (dispatch) => {
	try {
		const response = await httpRequestPromise<AboutCinemaItemType[]>(`${KINOKASSA_API_URL}/api/v2/cinema/about`);
		const {about} = _.find(response, ({cinema_id: cinemaID}) => cinemaID === currentCinemaID) || {};

		dispatch(setAboutCinema(about));
		// eslint-disable-next-line no-empty
	} catch (error) { }
};

export const updateSelectedDay = day => dispatch => {
	dispatch(setSelectedDay(day));
};

export const changePageTitle = title => {
	const separate = ' — ';
	const currentTitleArray = document.title.split(separate);
	const currentTitle = currentTitleArray[currentTitleArray.length - 1];

	document.title = title
		? title + separate + currentTitle
		: currentTitle;
};

export const closeSmartBanner = createAction(
	actions.CLOSE_SMART_BANNER,
);

export const getFondKinoAppData = () => async (dispatch, getState) => {
	dispatch(getAppDataRequest());

	try {
		const [appResponse, {supposed_city_id}] = await Promise.all([
			httpRequestPromise(
				`${KINOKASSA_API_URL}/api/v2/app?with_cities=true&with_regions=true&with_contacts=true`,
			),
			httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/location`)
		]);

		const {
			cinemas,
			cities,
			regions,
			contacts,
			branding,
			modules,
			country,
		} = appResponse;

		const state = getState();
		const isMonitor = getIsMonitor(state);

		const getSupposedCityId = () => isMonitor ? KRASNODAR_CITY_ID : (supposed_city_id || get(cities[0], 'id'));

		let currentCityId = getCurrentCityId(state);

		const isCurrentCityInCities = !!cities.find(({id}) => id === currentCityId);

		currentCityId = isCurrentCityInCities ? currentCityId : getSupposedCityId()

		const currentCity = cities.find(({id}) => id === currentCityId) || {};
		const currentCinema = cinemas.find(({city_id}) => city_id === currentCityId) || {};

		const normalizeContacts = normalizeContactsData(contacts, currentCinema.id);

		const citiesWithRegions = cities.map(city => {
			const region = regions.find(({id}) => id === city.region_id) || {};

			return {
				...city,
				region: region.title
			};
		});

		dispatch(getAppDataSuccess({
			cinema: {
				..._.omit(currentCinema, 'alert'),
				city: currentCity.title,
				display_settings: getDisplaySettingsOfCinemasForCurrentCity(cinemas, currentCityId)
			},
			allCities: citiesWithRegions,
			allCinemas: cinemas,
			allContacts: contacts,
			alert: currentCinema.alert,
			modules,
			branding,
			country
		}));

		dispatch(setContacts(normalizeContacts));
		dispatch(saveNewCurrentCityId(currentCityId));

		return {cityId: currentCityId};
	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}

	return null;
};

export const getSeveralCinemasAboutRequest = (currentCinemasIds) => async (dispatch) => {
	try {
		const response = await httpRequestPromise<AboutCinemaItemType[]>(`${KINOKASSA_API_URL}/api/v2/cinema/about`);
		const aboutData = intersectionWith(
			response,
			currentCinemasIds,
			(aboutItem, cinemaId) => aboutItem.cinema_id === cinemaId,
		);

		dispatch(setSeveralCinemasAboutData(aboutData));
	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}
};

export const getHallsRequest = (cinemaId) => async (dispatch) => {
	try {
		const halls = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/cinema/${cinemaId}/hall`);

		dispatch(setHalls(halls));
	} catch (error) {
		dispatch(setAppError(actions.INTERNAL_SERVICE_ERROR));
	}
};

const handleGeneralRequests = async (dispatch, getState) => {
	const state = getState();

	const cityId = getCurrentCityId(state);
	const currentCinemaId = getCurrentCinemaId(state);
	const isKinokassa = getIsKinokassa(state);
	const offsetNews = getOffset(state);

	const afishaRoutes = ['/', `/${cityId}`, `/${cityId}/`];

	if (!afishaRoutes.includes(location.pathname)) {
		const today = formatMoment(getBusinessDay());

		dispatch(getQualifiers(today, cityId));
	}

	dispatch(getPromos(currentCinemaId, cityId));
	dispatch(getNews(currentCinemaId, cityId, offsetNews));
	dispatch(getCustomPagesRequest(currentCinemaId, cityId));

	if (!isKinokassa) {
		dispatch(getBannersRequest(currentCinemaId, cityId));
	}

	if (currentCinemaId) {
		dispatch(getAboutCinemaRequest(currentCinemaId));
	}
}

export const handleChangingCity = (dispatch) => {
	batch(() => {
		[
			resetCurrentCinemaInfo(),
			resetBanners(),
			resetPages(),
			resetPromos(),
			resetNews(),
			updateCurrentCinema,
			handleGeneralRequests
		].forEach(dispatch)
	})
}