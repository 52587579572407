import { useCallback, useState } from "react";
import { FilterValue } from "app/modules/filters/components/FilterValue";
import { getCurrentPage, getSelectedFiltersToDisplaying } from "app/modules/filters/redux/selectors";
import { resetFilters, updateFilterValue } from "app/modules/filters/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { FILTER_PANEL_ELEMENTS, FiltersPage, FilterValueType } from "app/modules/filters/types";
import classNames from "classnames";

export const SelectedFiltersList = () => {
	const dispatch = useDispatch();

	const selectedFilters = useSelector(getSelectedFiltersToDisplaying);
	const currentPage = useSelector(getCurrentPage);
	const isProCulturePage = currentPage === FiltersPage.PUSHKIN_CARD;
	const [hidingFilterID, setHidingFilterID] = useState<string | boolean>('');

	const handleClearButtonClick = useCallback(() => {
		const filters = _.chain(selectedFilters)
			.map(({filterKey}) => filterKey)
			.uniq()
			.value();

		dispatch(resetFilters(isProCulturePage
			? _.without(filters, FILTER_PANEL_ELEMENTS.PUSHKIN_CARD)
			: filters));
	}, [selectedFilters]);

	const handleClick = (value: typeof selectedFilters[number]) => {
		dispatch(updateFilterValue(value.filterKey, value, FilterValueType.MAIN))
		setHidingFilterID('');
	};

	const handleFilterValueClick = useCallback((value: typeof selectedFilters[number]) => {
		setHidingFilterID(value.id);
		_.delay(handleClick, 200, value);
	}, []);

	return (
		<div className={classNames("selected-filters-list container", {
			"filters-container": currentPage === FiltersPage.RELEASE
		})}>
			{selectedFilters.length > 1 && (
				<FilterValue
					onClick={handleClearButtonClick}
					value={null}
					withCloseIcon
					classes="selected-clear-button"
				/>
				)
			}
			{!!selectedFilters.length && (
				selectedFilters.map((value) => (
					<FilterValue
						key={value.title}
						onClick={!(isProCulturePage && value.filterKey === FILTER_PANEL_ELEMENTS.PUSHKIN_CARD)
							? handleFilterValueClick
							: _.noop}
						value={value}
						title={value.title}
						size="small"
						withCloseIcon
						classes={hidingFilterID === value.id ? "filter-value--hiding" : ''}
					/>
				))
			)}
		</div>
	)
}