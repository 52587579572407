import * as style from 'css/release-item.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import {
	getReleaseSelector,
	getIsFetching,
	getSeanceDates,
} from 'app/selectors/ReleaseSelectors';
import React, {memo, useEffect, useState} from 'react';
import Flex from 'app/ui/Flex/Flex';
import ReleasePoster from 'app/components/release_item/ReleasePoster';
import { arrowIconDownGray } from 'app/constants/IconsConstants';
import ReleaseDetails from 'app/components/release_item/ReleaseDetails';
import { BadgeType } from 'app/components/release_item/types';
import ReleaseBadge from 'app/components/release_item/ReleaseBadge';
import withScroll from 'app/hocs/withScroll';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import { ID_ELEMENT_RELEASE_SCHEDULE } from 'app/components/release_page/constants';
import { getCinema } from 'app/selectors/AppSelectors';
import { showPopup } from 'app/actions/AppActions';
import { setYaReachGoal } from 'app/selectors/Helpers';
import { useMobileViewBreakPoint, usePrevious } from 'app/hooks';
import { MOBILE_MAX_SIZE } from 'app/constants/AppConstants';
import PopupComponent from "app/components/PopupComponent";
import ReleaseQualifiersWrapper from 'app/components/ReleaseQualifiersWrapper';

const BuyTicketsButton = withScroll(KeydownEnterButton);

function ReleaseItem({setPoster, isReleasePage = false}) {
	const dispatch = useDispatch();
	const release = useSelector(getReleaseSelector);
	const isFetching = useSelector(getIsFetching);
	const cityTitle = useSelector(getCinema).city;
	const seanceDates = useSelector(getSeanceDates);
	const [isOpenDetails, setIsOpenDetails] = useState(false);
	const prevRelease = usePrevious(release);

	const {isMobileView} = useMobileViewBreakPoint(MOBILE_MAX_SIZE);
	const {
		id,
		title,
		age_rating,
		genres,
		poster,
		all_qualifiers,
		trailer,
	} = release;

	useEffect(() => {
		if (release.id !== prevRelease?.id) {
			setPoster('');
		}
	}, [release.id]);

	useEffect(() => {
		if (release?.screenshots?.length) {
			setPoster(release?.screenshots[0].split('?mode')[0]);
		} else {
			setPoster('');
		}
	}, [release.id]);

	useEffect(() => {
		if (release?.screenshots?.length && isReleasePage) {
			setPoster(release?.screenshots[0].split('?mode')[0]);
		} else {
			setPoster('');
		}
	}, [release, isReleasePage]);

	const renderAgeRating = () => {
		if (!age_rating) return null;

		return <ReleaseBadge title={age_rating} type={BadgeType.accent} />
	};

	const renderGenres = () => {
		if (!genres.length) return null;

		return genres.map(({title, id}) => (
			<ReleaseBadge title={title} type={BadgeType.white} key={id} />
		))
	};

	const handleToggleDetails = (value: boolean) => {
		setIsOpenDetails(value);
	}

	const handleShowTrailer = (event) => {
		event.preventDefault();

		const {src, thumb = ''} = event.target.dataset;
		const popupContentNode = (
			<video poster={thumb} controls autoPlay muted playsInline>
				<source src={src} type="video/mp4" />
			</video>);

		dispatch(showPopup({
			popupContentNode,
			className: style.trailer,
			withCloseButton: true,
		}));

		setYaReachGoal('open-trailer', {
			movie: release.title,
			release_id: release.id,
			city: cityTitle,
		});
	}

	return !isFetching && id ? (
		<Flex classes={`${style.release} kassa-container`} element="section">
			<ReleasePoster
				poster={poster}
				qualifiers={all_qualifiers}
				isReleasePage={true}
			/>
			<Flex classes={style.releaseDesc} direction="column">
				<ReleaseQualifiersWrapper
					wrapperElement={Flex}
					classes={style.releaseBadges}
					qualifiers={all_qualifiers}
					withProCultureBadge
					isReleasePage
				/>

				{title ? <h1 className={style.releaseTitle}>{title}</h1> : null}

				{age_rating || !!genres.length
					? (
						<Flex classes={style.releaseBadges}>
							{renderAgeRating()}
							{renderGenres()}
						</Flex>
					) : null}
				<Flex direction={isMobileView ? 'column' : 'row'}>
					{seanceDates.length
						? (
							<BuyTicketsButton className={`button button--accent ${style.releaseButton}`} targetElementId={ID_ELEMENT_RELEASE_SCHEDULE}>
								{i18n.t('BuyTickets')}
							</BuyTicketsButton>
						) : null}
					{trailer?.preview || trailer?.hd
						? (
							<KeydownEnterButton
								data-src={trailer?.preview ?? trailer?.hd}
								data-thumb={trailer?.thumbnail}
								onClick={handleShowTrailer}
								className={`button button--semitransparent ${style.releaseButton} ${style.releaseButtonTrailer}`}
							>
								{i18n.t('WatchTrailers')}
							</KeydownEnterButton>
						) : null}
				</Flex>

				<a className={`text text--link-accent ${style.releaseLink}`} onClick={() => handleToggleDetails(true)}>
					{i18n.t('MoreDetails')}
					<span className={style.releaseIcon}>{arrowIconDownGray}</span>
				</a>
			</Flex>
			<Portal>
				<PopupComponent
					withCloseButton={false}
					popupContentNode={isOpenDetails ? <ReleaseDetails release={release} onClose={(value) => handleToggleDetails(value)} /> : null}
					mobileViewBreakpoint={Infinity}
					hidePopup={handleToggleDetails}
				/>
			</Portal>
		</Flex>
	) : null
}

export default memo(ReleaseItem);
